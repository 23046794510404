@use './colors';
@import url('https://fonts.googleapis.com/css2?family=Crete+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pushster&display=swap');

@mixin rounded {
  border-radius: 4px !important;
}

@mixin dropShadow {
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 5px !important;
}

html {
  // Reference: https://snook.ca/archives/html_and_css/font-size-with-rem
  font-size: 62.5%;

  body {
    background-color: #b8e4f0;
    background-size: 192px;
    background-image: url('../../public/images/cat-tile.png') !important;
    font-family: 'Crete Round', serif;

    * {
      font-size: 1.7rem !important;
    }

    h1 {
      font-size: 3.2rem !important;
    }

    h2 {
      font-size: 2.4rem !important;
    }

    h3 {
      font-size: 1.872rem !important;
    }

    h4 {
      font-size: 1.792rem !important;
    }

    h5 {
      font-size: 1.328rem !important;
    }

    h4 {
      font-size: 1.2rem !important;
    }

    .rounded {
      @include rounded;
    }

    .drop-shadow {
      @include dropShadow;
    }

    select,
    button {
      &:disabled {
        opacity: 0.7;
      }
    }

    .button__secondary {
      @include rounded;
      @include dropShadow;
      transition-property: all;
      transition-duration: 0.25s;
      padding: 12px 36px;
      color: white;
      border: 0;
      background-color: colors.$color-secondary !important;

      &:enabled:hover {
        background-color: colors.$color-primary !important;
      }
    }
  }
}
