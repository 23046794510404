@use '../../styles/colors';
@mixin absolute-position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.img-container {
  width: 100%;
  height: 100%;
  min-width: 72px;
  min-height: 72px;
  position: relative;

  .spinner-container {
    @include absolute-position;
    z-index: 999;
    background-color: #{colors.$color-secondary}aa;

    .spinner-container__spinner-background {
      @include absolute-position;
      max-width: 72px !important;
      max-height: 72px !important;

      margin: 12px;
      border-radius: 50px;
      background-color: white;
    }

    img.spinner-container__spinner {
      @include absolute-position;
      object-fit: cover;
      max-height: 80%;
    }
  }
}
