@use '../../styles/colors';
@mixin transition-all {
  transition-property: all;
  transition-duration: 250ms;
}

.gallery-container {
  width: 100%;

  .gallery-container__list {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .gallery-container__list-item {
      @include transition-all;
      height: 40vh;
      flex-grow: 1;
      margin: 4px;
      list-style: none;
      background-color: colors.$color-secondary;

      @media (max-width: 800px) {
        height: 30vh;
      }

      @media (max-width: 480px) {
        height: 20vh;
      }

      img {
        @include transition-all;
        &:not(.spinner-container__spinner) {
          margin: 0 auto;
          object-fit: cover;
          min-width: 100%;
          max-height: 100%;
          vertical-align: bottom;
          filter: grayscale(80%);
        }

        &:hover {
          filter: grayscale(0%);
        }
      }
    }
  }
}

.gallery-status-div {
  .gallery-status-div__spinner {
    height: 50vh;
    position: relative;

    div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .gallery-status-div__text {
    h3 {
      padding: 16px;
      background-color: white !important;
    }
  }
}
