@use '../../styles/colors';
.info-container {
  position: relative;
  background-color: colors.$color-secondary;

  .info-container__div {
    padding: 16px;
    color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .img-container {
    display: flex;

    img {
      flex-grow: 1;
      min-width: 100%;
      max-height: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}
