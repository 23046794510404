@use '../../styles/colors';

.select-container {
  background-color: white;

  select {
    text-align: center;
    border: 0 !important;
    color: colors.$color-primary !important;
    border-radius: 0px !important;
    background-color: transparent !important;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%236e3cbc'><polygon points='0,35 100,35 50,80'/></svg>") !important;

    &:hover:enabled {
      cursor: pointer;
      color: colors.$color-secondary !important;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%237267cb'><polygon points='0,35 100,35 50,80'/></svg>") !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:disabled {
      &:not(.loading) {
        background-image: none !important;
      }

      // https://icons.getbootstrap.com/icons/hourglass/
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%237267cb' viewBox='0 0 16 16'><path d='M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z'/></svg>") !important;
    }
  }
}
