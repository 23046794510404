@use '../../styles/colors';
nav {
  background-color: colors.$color-primary;

  a {
    color: white !important;
    font-size: 42px !important;
    font-size: 4.2rem !important;
    font-family: 'Pushster', cursive;
  }

  img {
    max-width: 64px;
  }
}
